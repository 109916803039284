.toggle-content {
	display: none;
	height: 0;
	overflow: hidden;
	@include transition(height, 350ms);
}

.toggle-content.is-visible {
	display: block;
	height: auto;
}