/*
 ::: GRID COLLABORATIONS PUBLISHING
-------------------------------------------------- */
.grid-collabs-publishing {
  @extend %grid-square-container-6_items;
}

.grid-collabs-publishing-item {
  @extend %grid-square-item;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacer * 2;
  border-bottom: none;

  &:last-child {
    border-bottom: none !important;
  }

  @media only screen and (min-width: 460px) {
    border-bottom: 1px dashed black;

    &:nth-last-child(-n + 2) {
      border-bottom: none;
    }
  }

  @include mq($from: md) {
    &:nth-child(-n + 3) {
      border-top: $dashed-border;
    }
    &:nth-child(4) {
      border-bottom: none;
    }
  }

  @include mq($from: xl) {
    &:nth-child(-n + 3) {
      border-bottom: none;
    }
  }
}

.grid-collabs-publishing-overlay {
  @extend %grid-overlay;

  & + div {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
}

.grid-collabs-publishing__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  margin-bottom: 10px;
}

.grid-collabs-publishing__infos {
  height: 40%;

  // Label
  & .music-label {
    font-size: 0.5em;
    vertical-align: bottom;
  }
}

.grid-collabs-publishing__artist-name {
  margin: 0 0 $spacer*2 0;
  font-size: 1.25em;

  @media only screen and (min-width: 460px) {
    font-size: 1em;
  }
}

.grid-collabs-publishing__project-name {
  display: inline;
  margin: 0 $spacer*1 0 0;
  font-size: 1.25em;
  font-family: $font-secondary;

  @media only screen and (min-width: 460px) {
    font-size: 1em;
  }

  // Exceptions for 1x pixel ratio screen
  @media screen and (-webkit-max-device-pixel-ratio: 1) {
    font-size: 1em;
  }

  @media screen and (max-resolution: 1x) {
    font-size: 1em;
  }
}
