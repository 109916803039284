@page:first {
  margin-top: 1cm;
}

@page {
  margin: 1.5cm 0.75cm 0.5cm 0.75cm;
}

@media print {
  // Remove menu navbar
  .header-menu-navbar,
  .footer-navbar {
    display: none;
  }

  .artist__screen {
    display: none;
  }

  .artist-name {
    margin: 0 0 0.5cm 0;
    font-size: 34pt;
    font-style: italic;
  }

  .artist-infos-wrapper {
    columns: 2;
    column-gap: 20px;
    column-rule: 1px dashed $black;
    font-size: 0.75em;
  }

  .artist-category {
    border-bottom: none !important;
    padding-bottom: 0 !important;
    border-top: 1px dashed $black;
  }

  .artist-category:first-child {
    border: none;
  }

  .artist-category .artist-bio {
    margin-bottom: 0.5cm;
  }

  .artist-bio {
    break-after: page;
  }

  .artist-image__print {
    display: block;
    height: 50vh;
    // max-width: calc(100% - 1cm);
    margin-bottom: 0.5cm;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .artist-category {
    break-inside: avoid;
  }

  .category-content,
  .category-content li {
    break-inside: avoid;
  }

  .artist-gallery-container {
    display: none;
  }
}

@media screen {
  .artist__print {
    display: none;
  }
}