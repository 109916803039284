.section-collaboration {
  lost-utility: clearfix;
  display: flex;
  padding: $gutter-width;

  @include mq($until: xl) {
    flex-wrap: wrap;
  }
}

.collaboration-nav {
  lost-column: 1/2 0 0;

  @include mq($until: xl) {
    lost-column: 1 0 0;
    order: 2;
  }
}

.collaboration-list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: $spacer*2;

  // Target all <p> inside collaboration-list
  & p {
    font-family: $font-secondary;
    font-size: $font-size-normal;
  }
}

.collaboration-list__wrapper {
  display: flex;
  margin-top: $spacer*2;
  margin-bottom: $spacer*2;

  &:first-child {
    margin-top: 0;
  }

  & span.list-square {
    position: relative;
  }

  &.is-active {
    // & .collaboration-name {
    //   color: $black;
    // }
    & span.list-square {
      background-color: $black;
    }
  }
}

.collaboration-link {
  text-decoration: none;
}

.collaboration-name {
  margin-top: 0;
  margin-bottom: $spacer*2;
  font-weight: 400;
  text-transform: uppercase;
}

.collaboration-text {
  font-family: $font-secondary;
  font-size: $font-size-normal;
  margin: 0;
}

.collaboration-more-link {
  font-family: $font-primary;
  font-size: $font-size-small;
  text-decoration: none;
}

/*
 :::  ILLUSTRATION
-------------------------------------------------- */
.collaboration-illustration {
  lost-column: 1/2 0 0;
  margin-left: 0;
  margin-right: 0;
  padding-left: $spacer*2;

  @include mq($until: xl) {
    lost-column: 1 0 0;
  }
}

.collaboration-illustration__image {
  width: 100%;
  // max-height: 500px;
  // object-fit: cover;
}

.collaboration-illustration__caption {
  margin-top: $spacer*1;
}