/*
 :::  Mixins & keyframes
-------------------------------------------------- */
// Transition
@mixin transition($properties: all, $duration: 200ms, $method: cubic-bezier(0.645, 0.045, 0.355, 1), $delay: 0ms) {
    $value: ();
    @each $property in $properties {
        @if length($value) > 0 {
            $value: append($value, unquote(",") #{$property} $duration $method $delay);
        }
        @else {
            $value: append($value, #{$property} $duration $method $delay);
        }
    }
    transition: $value;
}

// Border
@mixin border($direction: all, $width: $border-width, $style: $border-style, $color: $black) {
  border-#{$direction}: $width $style $color;
}