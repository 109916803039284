/*
 :::  Fonts Declaration
-------------------------------------------------- */
// Lido STF
@font-face {
  font-family: 'Lido STF';
  src: url('../fonts/Lido-STF/LidoSTF.woff2') format('woff2'),
      url('../fonts/Lido-STF/LidoSTF.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lido STF';
  src: url('../fonts/Lido-STF/LidoSTF-Italic.woff2') format('woff2'),
      url('../fonts/Lido-STF/LidoSTF-Italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

// Neuzeit
@font-face {
  font-family: 'NeuzeitS LT Book';
  src: url('../fonts/Neuzeit/NeuzeitSLT-Book.woff2') format('woff2'),
      url('../fonts/Neuzeit/NeuzeitSLT-Book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NeuzeitS LT Book';
  src: url('../fonts/Neuzeit/NeuzeitSLT-BookHeavy.woff2') format('woff2'),
      url('../fonts/Neuzeit/NeuzeitSLT-BookHeavy.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

/*
 :::  Headings
-------------------------------------------------- */
h1 {
  font-family: $font-secondary;
  font-size: $font-size-large;
  font-weight: 300;

  @media screen and (-webkit-max-device-pixel-ratio: 1) {
    font-size: 1.75em;
  }
  
  @media screen and (max-resolution: 1x) {
    font-size: 1.75em;
  }
}

h2 {
  font-weight: normal;
}