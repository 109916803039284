.filters,
.events {
  padding: $spacer * 4;
}

.filters {
  display: flex;
  flex-direction: column;
  border-top: 1px dashed $black;

  @include mq($from: sm) {
    flex-direction: row;
    align-items: center;
  }
}

.filters label {
  @include mq($until: sm) {
    margin-bottom: $spacer * 2;
  }

  @include mq($from: sm) {
    margin-right: $spacer * 2;
  }
}

.filters select {
  appearance: none;
  width: fit-content;
  max-width: 125px;
  padding: 5px 10px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAxNCI+CiAgPHBhdGggZmlsbD0iIzAwMCIgZD0ibTIyLjM3ODIgMi4xMTEyLjM1MzUuMzUzNi4zNTM2LS4zNTM2LS4zNTM2LS4zNTM1LS4zNTM1LjM1MzVabS05Ljc3NzYgOS43Nzc2LjM1MzYuMzUzNS0uMzUzNi0uMzUzNVptOS4wNzA1LTEwLjQ4NDcuMzUzNS0uMzUzNS0uMzUzNS0uMzUzNi0uMzUzNi4zNTM2LjM1MzYuMzUzNVptLTkuNzc3NiA5Ljc3NzYtLjM1MzYuMzUzNS4zNTM2LjM1MzYuMzUzNS0uMzUzNi0uMzUzNS0uMzUzNVpNMi4xMTU5IDEuNDA0MWwuMzUzNi0uMzUzNUwyLjExNTkuNjk3bC0uMzUzNS4zNTM2LjM1MzUuMzUzNVptLS43MDcuNzA3MS0uMzUzNi0uMzUzNS0uMzUzNi4zNTM1LjM1MzYuMzUzNi4zNTM1LS4zNTM2Wm05Ljc3NzUgOS43Nzc2LS4zNTM2LjM1MzUuMzUzNi0uMzUzNVptLjcwNzEuNzA3MS0uMzUzNi4zNTM2LjM1MzYuMzUzNS4zNTM1LS4zNTM1LS4zNTM1LS4zNTM2Wk0yMi4wMjQ2IDEuNzU3N2wtOS43Nzc2IDkuNzc3NS43MDcyLjcwNzEgOS43Nzc1LTkuNzc3NS0uNzA3MS0uNzA3MVptLS43MDcxIDAgLjcwNzEuNzA3LjcwNzEtLjcwNy0uNzA3MS0uNzA3MS0uNzA3MS43MDdabS05LjA3MDUgOS43Nzc1IDkuNzc3Ni05Ljc3NzUtLjcwNzEtLjcwNzEtOS43Nzc2IDkuNzc3NS43MDcxLjcwNzFaTTEuNzYyNCAxLjc1NzdsOS43Nzc1IDkuNzc3NS43MDcxLS43MDcxLTkuNzc3NS05Ljc3NzUtLjcwNzEuNzA3Wm0wIC43MDcuNzA3LS43MDctLjcwNy0uNzA3MS0uNzA3MS43MDcuNzA3LjcwNzJabTkuNzc3NSA5LjA3MDVMMS43NjI0IDEuNzU3N2wtLjcwNzEuNzA3IDkuNzc3NSA5Ljc3NzYuNzA3MS0uNzA3MVptLjcwNzEuNzA3MS0uNzA3MS0uNzA3MS0uNzA3MS43MDcxLjcwNzEuNzA3Mi43MDcxLS43MDcyWm0wIDAtLjcwNzEuNzA3Mi43MDcxLS43MDcyWm0wLS43MDcxLS43MDcxLjcwNzEuNzA3MS43MDcyLjcwNzItLjcwNzItLjcwNzItLjcwNzFaIi8+Cjwvc3ZnPgo=");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 7.5px);
  background-position-y: center;
  border: 1px dashed $black;
  padding-right: 30px;
  text-overflow: ellipsis;

  &:not(:last-child) {
    @include mq($until: sm) {
      margin-bottom: $spacer * 2;
    }

    @include mq($from: sm) {
      margin-right: $spacer * 2;
    }
  }

  &:focus {
    outline: none;
    border: 1px solid $black;
  }
}

.call-to-actions {
  display: flex;
  flex-direction: column;

  @include mq($from: sm) {
    align-items: flex-end;
  }

  & a {
    font-weight: 700;
    text-decoration: none;
    border: 1px solid $black;
    padding: $spacer /2 $spacer;

    &:hover {
      background-color: $black;
      color: $white;
    }
  }

  & a:not(:last-child) {
    margin-right: $spacer * 2;
  }
}

.event {
  display: flex;
  flex-direction: column;
  padding-bottom: $spacer * 4;
  border-bottom: 1px dashed $black;
  text-decoration: none;

  @include mq($from: sm) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &:not(:last-child) {
    margin-bottom: $spacer * 8;
  }
}

.event .date {
  display: inline-block;
  width: fit-content;
  padding: 2px 4px;
  color: $white;
  background-color: $black;
  border-radius: 4px;
  font-size: $font-size-small;
}

.event .artist {
  margin: 5px 0;
  font-family: $font-secondary;
  font-size: $font-size-normal;
  font-weight: 400;
  font-style: italic;
}


.event .location,
.event .venue {
  font-size: $font-size-normal * 0.8;
  color: $black;
}
.event .location {
  margin-bottom: $spacer;
}

.event .venue {
  font-weight: 700;
}

.event:hover {
  & .location, & .venue {
    color: $black;
  }

  & .ticket-square {
    background-color: $black;
  }
}

.event .ticket {
  display: flex;
  align-items: center;
  margin-bottom: $spacer * 4;

  @include mq($until: sm) {
    margin-top: $spacer * 4;
  }

  &-square {
    display: block;
    width: 15px;
    min-width: 15px;
    height: 15px;
    margin-right: 10px;
    border: 1px solid $black;

    @include transition(background-color);
  }

  &-text {
    font-size: 1.25em;
    text-transform: uppercase;
    color: $black;
  }
}

.no-events {
  display: none;
  justify-content: center;
  margin: $spacer * 8 auto;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;

  &.is-visible {
    display: flex;
    opacity: 1;
  }
}

.subtitle {
  max-width: fit-content;
  padding: $spacer;
  font-size: $font-size-large;
  font-weight: 700;
  color: $white;
  background-color: $blue;
}

.sponsor {
  display: flex;
  align-items: center;
  padding: 0 $spacer * 4;
  font-weight: 400;

  & a {
    font-weight: 700;
  }

  & svg {
    width: 125px;
  }
}

.loader {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: $spacer * 8 auto;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;

  & p {
    font-size: $font-size-small * 1.25;
    font-weight: 700;
  }

  &.is-visible {
    display: flex;
    opacity: 1;
  }
}

.spinner,
.spinner::after {
  z-index: 2;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.spinner {
  display: block;
  font-size: 5px;
  border-top: 0.5em solid rgba(black, 20%);
  border-right: 0.5em solid rgba(black, 20%);
  border-bottom: 0.5em solid rgba(black, 20%);
  border-left: 0.5em solid black;
  animation: spin 0.5s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
