/*
 ::: GRID SQUARE
-------------------------------------------------- */
// Grid Square sizes
$grid-item-xxs: 1;
$grid-item-xs: 2;
$grid-item-md: 3;
$grid-item-lg: 4;
$grid-item-xl: 6;

// Border
$dashed-border: 1px dashed $black;

// Container
%grid-square-container {
  // The content width you use on your website
  --content-width: 100vw;
  // The size of the gutter  
  --gutter: 0px;
  // The amount of columns
  --columns: 1;
  // This is the calculation for the row height.   
  --row-size: calc(
    ( var(--content-width) - (var(--gutter) * (var(--columns) - 1))) / var(--columns) );
  
  display: grid;
  width: 100%;
  max-width: var(--content-width);
  
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-auto-rows: var(--row-size);

  grid-column-gap: var(--gutter);
  grid-row-gap: var(--gutter);
}

// Container max 3 items
%grid-square-container-3_items {
  @extend %grid-square-container;
  
  @media only screen and (min-width: 460px) {
    --columns: 2;
  }

  @include mq($from: md) {
    --columns: 3;
  }
}

// Container max 6 items
%grid-square-container-6_items {
  @extend %grid-square-container-3_items;

  @include mq($from: xl) {
    --columns: 6;
  }
}

// Item
%grid-square-item {
  position: relative;
  border: $dashed-border;
  border-right: $dashed-border;
  border-bottom: none;

  &:last-child {
    border-bottom: $dashed-border !important;
  }

  // Small screen - 2 items
  @media only screen and (min-width: 460px) {
    border-right: none;
    border-top: none;
    border-bottom: $dashed-border;

    // Every items of first line
    &:nth-child(-n + 2) {
      border-top: $dashed-border;
    }
    // Every 2 items and last item
    &:nth-child(2n) {
      border-right: $dashed-border;
    }
    // Last child
    &:last-child {
      border-right: $dashed-border !important;
    }
  }
    
  // Medium screen - 3 items
  @include mq($from: md) {
    // Override small screen border
    &:nth-child(2n) {
      border-right: none;
    }
    // Last item of each line
    &:nth-child(3n) {
      border-right: $dashed-border;
    }
    // Every items of first line
    &:nth-child(-n + 3) {
      border-top: $dashed-border;
    }
  }

  // Large screen - 6 items
  @include mq($from: xl) {
    border-top: none;
    border-right: none;
    border-bottom: $dashed-border;

    // Override medium screen border
    &:nth-child(3n) {
      border-right: none;
    }
    // Last item of each line
    &:nth-child(6n) {
      border-right: $dashed-border;
    }
    // First line
    &:nth-child(-n + 6) {
      border-top: $dashed-border;
    }
    // Last child
    &:last-child {
      border-right: $dashed-border;
    }
  }
}

// Remove background on mobile and tablet
.mobile %grid-square-item {
// .tablet %grid-square-item {
  background: transparent !important;
}

// Overlay
%grid-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &:hover %grid-overlay__element {
    opacity: 1;
    top: 50%;
  }
}

%grid-overlay__element {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  margin: 0;
  color: white;
  opacity: 0;
  text-align: center;
  @include transition(all, 250ms);
}