// Container of artist informations and gallery
.section-artist {
  lost-utility: clearfix;
  display: flex;
  padding: $gutter-width;

  @include mq($until: xl) {
    flex-wrap: wrap;
  }
}

// Each part (infos on the left, gallery on the right) take half the screen
.artist-infos-container, .artist-gallery-container {
  lost-column: 1/2 0 0;

  @include mq($until: xl) {
    lost-column: 1 0 0;
  }
}

// Put gallery on top of the page
.artist-infos-container {
  @include mq($until: xl) {
    order: 2;
  }
}

.artist-category {
  @extend %category;
  position: relative;
  cursor: auto;
  // Give a padding top to every category except the first one
  &:not(:first-of-type) {
    padding-top: $spacer*2;
    // Make visible + and - when hovered
    // & .artist-category__title:after {
    //     opacity: 1;
    //     right: 0;
    // }
  }
}

// Category title
.artist-category__title {
  @include transition(all);
  cursor: pointer;
  &:hover {
    color: $grey;
  }
}

.artist-category__list {
  padding: 0;
  & .category-content {
    display: flex;
    margin-top: $spacer*4;
    margin-bottom: $spacer*4;

    & .artist-category_emails-wrapper {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      // Fix a weird visual bug on text
      & > a {
        line-height: 1.2;
      }
    }

    // Mobile font-size
    @include mq($until: sm) {
      font-size: 0.75em;
    }
  }
}

.artist-bio {
  // Remove margins to heading
  margin: 0;

  // Mobile font-size
  @include mq($until: sm) {
    font-size: 1.5em;
  }
}

.artist-category__content {
  font-size: $font-size-normal;
}

.music-label {
  display: inline-block;
  vertical-align: middle;
  // Perfect alignment
  margin-top: -$spacer;
  padding: 4px;
  color: $white;
  background-color: $black;
  border-radius: 4px;
  font-size: $font-size-small;
}

// Gallery
.artist-gallery-container {
  padding-left: $gutter-width;

  @include mq($until: xl) {
    padding-left: 0;
    margin-bottom: $gutter-width;
  }

  & img {
    position: sticky;
    top: $gutter-width;
    height: calc(100vh - 140px);
    width: 100%;
    object-fit: cover;

    @include mq($until: xl) {
      max-height: 75vh;
    }

    @include mq($until: lg) {
      max-height: 50vh;
    }

    @include mq($until: sm) {
      max-height: 300px;
    }
  }
}

.artist-gallery__sticky-container {
  position: sticky;
  top: 20px;
}

.artist-category__album-link {
  text-decoration: none;
}

// Widgets
.bit-widget-container {
  margin: $spacer*4 0;
}