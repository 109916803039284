/*
 :::  Colors
-------------------------------------------------- */
// Brand
$blue: rgb(60, 110, 225);
$grey: rgb(164, 175, 179);
$red: rgb(235, 48, 57);

$white: white;
$darkgrey: darkgrey;
$black: black;

/*
 :::  Background Colors
-------------------------------------------------- */
.bg-blue {
  background-color: $blue;
}

.bg-grey {
  background-color: $grey;
}

.bg-red {
  background-color: $red;
}