.section-introduction {
  lost-utility: clearfix;
  padding: $gutter-width;
  display: flex;
  min-height: 140px;

  @include mq($until: lg) { 
    flex-direction: column;
  }

  // Introduction Wrapper
  .introduction {
    lost-column: 6/12 0 0;
    @include border(right);
  
    // Full width on small screen
    @include mq($until: lg) {
      lost-column: 12/12;
      // Remove border-right
      border-right: none;
      // Add border to bottom
      @include border(bottom);
    }
  }

  // Introduction Text
  .introduction-text {
    margin: 0 0 $spacer*1 0;
    padding-right: $spacer*2;
    // Remove padding right since there is no border-right anymore
    @include mq($until: lg) {
      font-size: $font-size-large * 0.85;
      margin: 0 0 $spacer*2 0;
      padding-right: 0;
    }
  }

  // Contact Wrapper
  .contact {
    width: 50%;
    position: relative;
    // To compensate gutter:0 on introduction text
    padding-left: $gutter-width;
    @include mq($until: lg) {
      width: 100%;
      margin: $spacer*4 0;
      // Remove padding-left for smaller screen
      padding-left: 0;
    }
  }
  
  .contact-title {
    font-weight: 700;
  }

  .contact-title, .contact-email {
    margin: 0;
  }

  .contact-email {
    font-size: $font-size-normal;
    word-break: break-word;

    @include mq($until: lg) {
      font-size: $font-size-normal * 0.85;
    }
  }
}

.newsletter-badge__wrapper {
  position: absolute;
  bottom: 0;
  margin: $spacer*2 0 0 0;
  @include mq($until: lg) {
    position: relative;
  }
}

.newsletter-badge {
  display: inline-block;
  vertical-align: middle;
  // Perfect alignment
  margin-top: -$spacer;
  padding: $spacer;
  color: $black;
  background-color: transparent;
  border: 1px solid $black;
  border-radius: 4px;
  outline: none;
  font-size: $font-size-small * $font-reducer-lg;
  cursor: pointer;
  @include transition(background-color color);

  &:hover {
    color: $white;
    background-color: $black;
  }
}