/*
 ::: FONTS
-------------------------------------------------- */
// Family
$font-primary: 'NeuzeitS LT Book', Helvetica, Arial, sans-serif;
$font-secondary: 'Lido STF', Helvetica, Arial, sans-serif;
// Size
$font-size-small: 0.875em;
$font-size-normal: 1.5em;
$font-size-large: 2em;
// Reducer
$font-reducer-lg: 0.85;
$font-reducer-xs: 0.75;

/*
 ::: GRID
-------------------------------------------------- */
// Sizes
$xs: 320px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
// Gutter
$gutter-width: 20px;
$spacer: 5px;
// Footer
$footer-height: 50px;
// Lost
@lost gutter 20px;
@lost rounder 99.999;

/*
 ::: BORDER
-------------------------------------------------- */
$border_width: 1px;
$border_style: dashed;