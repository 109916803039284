/*
 ::: NEWSLETTER OVERLAY
-------------------------------------------------- */
.nl-overlay {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  padding: $spacer*4;
  background-color: $blue;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 9999;
  @include transition(opacity);

  &.is-visible {
    opacity: 1;
    pointer-events: all;
  }
}

.nl-overlay__close-button {
  padding: 0;
  background-color: transparent;
  border: none;
  color: $white;
  font-size: $font-size-normal;
  font-weight: 600;
  cursor: pointer;
}

.nl__wrapper {
  --w: 55ch;

  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 20px;
  transform: translateY(-50%) translateX(-50%);
  color: $white;
}

.nl-title {
  margin: $spacer * 2;
  margin-bottom: $spacer * 8 !important;
  font-family: $font-secondary;
  font-size: $font-size-large;
  font-style: italic;
  text-align: center;

  // Remove asterisk added by Brevo
  &::after {
    content: none !important;
  }
}

/*
 ::: BREVO
-------------------------------------------------- */
.nl-row {
  display: flex;
  align-items: baseline;
  justify-content: center;

  @include mq($until: sm) {
    flex-direction: column;
    align-items: center;
  }
}

.nl-unsubscribe {
  display: block;
  margin-top: $spacer * 2 !important;
  font-size: 12px;
  line-height: 1.25 !important;
  color: white;
}

.nl-fields {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .sib-input {
    width: 100%;

    @include mq($from: sm) {
      width: var(--w);
    }

    & .form__label-row {
        width: 100%;
        max-width: var(--w);
    }
  }

  & > *:not(:last-child) {
    margin-bottom: $spacer * 4;
  }
}

.nl-optin {
  max-width: var(--w);
  user-select: none;

  & .entry__choice,
  & .entry__choice > * {
    cursor: pointer;
  }

  & label.entry__specification {
    display: inline !important;
    max-width: 30ch !important;
    text-align: left !important;
  }
}

.nl-submit {
  display: flex;
  flex-direction: column;
  align-items: center;

  & button {
    max-width: fit-content;
  }
}

.nl-error {
  width: 100%;
  padding: 10px;
  padding-bottom: 12px;
  margin: 0 !important;
  margin-top: 10px !important;
  background: $red !important;
  color: $white !important;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 700;
  line-height: 1;
}

#sib-container {
  background: none !important;
}

.sib-form {
  text-align: center;

  &_scroll {
    display: flex;
    justify-content: center;

    @include mq($until: sm) {
      flex-direction: column;
    }
  }

  .sib-input {
    & input {
      width: calc(100% - 2rem);
      padding: $spacer $spacer * 2;
      padding-left: 1rem !important;
      padding-right: 1rem !important;
      border: 1px dashed $white;
      background: transparent;
      color: $white;

      &:focus {
        border-color: $white;
        outline: none;
      }

      &::placeholder {
        color: $white;
      }

      &::selection {
        color: $blue;
        background-color: $white;
      }
    }
  }

  .sib-form-block__button {
    min-width: auto;
    margin: 0 $spacer;
    font-weight: 600;
    cursor: pointer;
    color: $blue;
    background-color: $white;
    border-radius: 4px;
    transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

    & svg {
      fill: currentColor !important;
    }

    &:hover {
      color: $white;
      background-color: $black;
    }

    &-disabled {
      cursor: not-allowed;
    }

    &-with-loader {
      min-height: 50px;
    }

    @include mq($until: sm) {
      margin-top: $spacer * 4;
    }
  }

  .entry__specification {
    margin-top: $spacer * 2 !important;
    font-size: 12px !important;
    color: $white !important;
  }

  #success-message {
    color:#085229;
    background-color:#e7faf0;
    border-radius:3px;
    border-color:#13ce66;
    max-width:540px;
  }

  #error-message {
    color:#661d1d;
    background-color:#ffeded;
    border-radius:3px;
    border-color:#ff4949;
    max-width:540px;
  }
}

.grecaptcha-badge {
  display: none !important;
}
