// Header menu
.header-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: $gutter-width;
}

.header-menu-socials {
  display: none;
  padding: 0;
  margin: 0;
  margin-left: 10px;
  margin-right: -20px;

  @include mq($from: lg) {
    display: flex;
  }
}

.header-menu-social {
  &:not(last-child) {
    @include mq($from: sm) {
      margin-right: 4px;
    }

    @include mq($from: lg) {
      margin-right: 8px;
    }
  }

  & svg path {
    @include transition(fill, 0.25s);
  }

  & svg:hover {
    path {
      fill: $grey;
    }
  }

  @include mq($from: sm) {
    & svg {
      width: 14px;
      height: 14px;
    }
  }

  @include mq($from: lg) {
    & svg {
      width: 16px;
      height: 16px;
    }
  }
}

.tablet .header-menu {
  padding-bottom: 12px;
}

// Brand logo
.brand-logo {
  lost-column: 2/12;
  lost-column-cycle: 1;
  z-index: 9999;

  & img {
    min-width: 100px;
    max-width: 100px;
    // Reduce logo size on smaller screen
    @include mq($until: lg) {
      min-width: 85px;
      max-width: 85px;
    }
  }
}

// Navbar container
.header-menu-navbar {
  lost-column: 6/12;
  lost-offset: 4/12;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;

  @include mq($from: lg) {
    justify-content: space-between;
  }

  @include mq($until: lg) {
    lost-column: 11/12;
    lost-offset: 0;
    display: flex;
    justify-content: flex-end;
    // Remove lost margin and align with content
    margin-right: 0 !important;
  }
}

.tablet .header-menu-navbar {
  lost-column: 11/12;
  lost-offset: 0;
  display: flex;
  justify-content: flex-end;
  // Remove lost margin and align with content
  margin-right: 0 !important;
}

// Menu links wrapper
.header-menu-list {
  display: flex;
  flex-direction: row;
  padding: 0;
  // Align with contact section
  padding-left: $gutter-width;
  // Align with logo baseline
  margin: 0;

  & li {
    margin-left: $spacer*2;
    margin-right: $spacer*2;
    list-style: none;
  }

  @include mq($until: sm) {
    display: none;
  }
}

.tablet .header-menu-list {
  display: none;
}

.header-menu-sublist {
  position: absolute;
  height: 300px;
  width: 100%;
  top: 85px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $grey;
  z-index: 100;
}

// Menu links
.header-menu-link, .header-menu-open {
  padding: 2px;
  font-size: $font-size-normal * 0.85;
  font-weight: bold;
  text-decoration: none;

  // Reduce font-size on smaller screen
  @include mq($until: lg) {
    font-size: $font-size-normal * 0.8;
  }
}

// Active state for header-menu link
.header-menu-link.is-active, .menu-mobile-link.is-active {
  color: $black;
  // Each link has a different bg-color
  &.tour {
    background-color: $blue;
    color: $white;
  }

  &.management {
    background-color: $grey;
  }

  &.publishing {
    background-color: $red;
  }
}

ul.menu-mobile-sublist .management .menu-mobile-link.is-active {
  background-color: $grey;
}

ul.menu-mobile-sublist .publishing .menu-mobile-link.is-active {
  background-color: $red;
}

ul.menu-mobile-sublist .tour .menu-mobile-link.is-active {
  background-color: $blue;
}

// Menu open button
.header-menu-icon {
  display: none;
  height: 30px;
  width: 30px;
  cursor: pointer;

  &.is-hidden {
    display: none;
  }

  &.close {
    z-index: 9999;
  }

  @include mq($until: sm) {
    display: flex;
  }
}

.tablet .header-menu-icon.open {
  display: flex;

  &.is-hidden {
    display: none;
  }
}

.tablet body.menu-active .header-menu-icon.close {
  display: flex;
}


// Menu Mobile
.menu-overlay {
  position: fixed;
  top: calc(85px + 6px); // 5px padding + 1px border
  background-color: $white;
}

.menu-mobile, .menu-overlay {
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  @include transition(opacity, 0.25s);
  will-change: opacity;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

// Menu mobile is active
body.menu-active {
  & .menu-mobile, .menu-overlay {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  & .header-menu {
    @include transition(border-bottom, 0.25s);
    border-bottom: 1px dashed $black;
  }
}

.menu-mobile {
  position: absolute;
  display: flex;
  top: 0;
}

.menu-mobile-list {
  position: absolute;
  width: 100%;
  margin: 0;
  padding: $spacer * 4;

  & > li {
    padding: $spacer 0;
  }
}

.menu-mobile-link {
  padding: 2px;
  font-family: $font-primary;
  font-size: $font-size-large;
  font-weight: bold;
  text-decoration: none;
}

.menu-mobile-sublist {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 10px;

  & > li a {
    font-size: $font-size-normal;
    font-weight: normal;
    line-height: 1.25;
  }
}
