.carousel-cell {
  width: 100%;
}

// Navigation arrows
/* no circle */
.flickity-button {
  display: none;
}

.flickity-page-dots {
  bottom: 20px;
}

.flickity-page-dots .dot {
  height: $spacer;
  width: $spacer;
  border: 1px solid $black;
  background: transparent;

  &.is-selected {
    border: 1px solid $black;
    background-color: $black;
  }
}