.list-square, a.list-square__link {
  display: block;
  height: 15px;
  width: 15px;
  min-width: 15px;
  margin-right: 20px;
}

.list-square {
  content: '';
  top: 4px;
  // Align correctly with text
  border: 1px solid $black;
  @include transition(background-color);

  &.is-active {
    background-color: $black;
  }
}

a.list-square__link {
  top: 4px;
  position: relative;
  & > .list-square {
    &:hover {
      background-color: $black;
    }
  }
}
