/*
 ::: GRID ARTISTS
-------------------------------------------------- */
.grid-artists {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 0;
  margin: 0;

  @media only screen and (min-width: 440px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($from: lg) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.grid-artist {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 100%;
  background-color: $grey;
  border: 1px dashed $black;
  border-right: none;
  border-bottom: none;
  transition: filter 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: filter;

  .desktop &:hover,
  .mobile &.is-active,
  .tablet &.is-active {
    filter: grayscale(1);

    .grid-artist-overlay {
      opacity: 0.85;
    }

    .grid-artist-content {
      opacity: 1;
    }

    .grid-artist-name {
      transform: translateX(-50%) translateY(-50%);
    }

    .grid-artist-socials {
      opacity: 1;
      pointer-events: all;
      z-index: 10;
    }
  }

  @include mq($until: lg) {
    // Last square of a row
    &:nth-child(2n) {
      border-right: 1px dashed $black;
    }

    // Last grid row
    &:nth-last-child(-n+2) {
      border-bottom: 1px dashed $black;
    }
  }

  @include mq($from: lg) {
    // Last square of a row
    &:nth-child(4n) {
      border-right: 1px dashed $black;
    }

    // Last grid row
    &:nth-last-child(-n+4) {
      border-bottom: 1px dashed $black;
    }
  }
}

.grid-artist-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-artist-link {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;

  &.disabled {
    pointer-events: none;
  }
}

.grid-artist-overlay {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $darkgrey;
  opacity: 0;
  transition: opacity 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.grid-artist-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  top: 0;
  padding: 1em;
  color: white;
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.grid-artist-categories {
  font-size: 1.15em;
  font-weight: 700;
  pointer-events: none;

  // Only display categories on homepage
  .is-category & {
    opacity: 0;
    visibility: hidden;
  }

  .is-active & {
    pointer-events: all;
  }

  @include mq($from: md) {
    font-size: 1.5em;
  }

  @include mq($from: lg) {
    font-size: 1.25em;
  }
}

.grid-artist-name {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  margin: 0;
  font-size: 1.5em;
  font-family: $font-secondary;
  font-style: italic;
  line-height: 1;
  text-shadow: 2px 2px 4px rgb(0 0 0 / 18%);
  transition: transform .35s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: translateX(-50%) translateY(calc(-50% + 10px));
  will-change: tranform;
  pointer-events: none;

  .is-active & {
    pointer-events: all;
  }

  @include mq($from: sm) {
    font-size: 1.75em;
  }

  @include mq($from: md) {
    font-size: 2.25em;
  }

  @include mq($from: lg) {
    font-size: 1.85em;
  }
}

.grid-artist-socials {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  opacity: 0;
  z-index: 2;
  bottom: 1em;
  transition: opacity 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
  pointer-events: none;
}

.grid-artist-social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 5px;
  max-width: 30px;
  max-height: 30px;
  margin: 0.25em;
  margin-top: 0;
  background-color: white;
  border-radius: 50%;
  text-decoration: none;

  & svg {
    width: 24px;
    height: 24px;
    max-width: 24px;
    max-height: 24px;

    & path {
      fill: black;
    }
  }

   & span {
      font-size: 0.65em;
      font-weight: 700;
   }
}
