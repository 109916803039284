.section-legal-notice {
  lost-utility: clearfix;
  height: 100%;

  @include mq($until: lg) {
    // font-size: $font-size-normal * $font-reducer-lg;
  }

  & div {
    lost-column: 5/12;
    padding: 0 $spacer * 4;

    &:first-child {
      lost-column: 2/12;
    }

    & h5 {
      margin-top: 0;
    }

    & h6 {
      margin-bottom: 0;
    }

    & p {
      margin-top: $spacer * 2;
    }

    @include mq($until: lg) {
      lost-column: 1/1;
      margin: $spacer * 2 0;

      &:first-child {
        margin: $spacer * 2 0 $spacer * 4 0;
      }
    }
  }
}

.legal-notice__title {
  font-size: $font-size-normal * $font-reducer-lg;
  margin: 0;
}