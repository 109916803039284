/*
 :::  ALL
-------------------------------------------------- */
*,
*:after,
*:before {
  box-sizing: border-box;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  color: $black;
  background-color: $grey;
}

/*
 :::  HTML & BODY
-------------------------------------------------- */
html,
body {
  position: relative;
  height: 100%;
}

html {
  overflow-x: hidden;
  font-family: $font-primary;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
}

body {
  &.menu-active {
    overflow: hidden;
  }
}

main {
  min-height: 100vh; /* will cover the 100% of viewport */
  display: block;
  position: relative;
  padding-bottom: $footer-height;

  @include mq($until: xl) {
    padding-bottom: $footer-height * 1.5;
  }
}

/*
 :::  LINKS
-------------------------------------------------- */
a {
  color: $black;
  @include transition(background-color color);
  &:hover {
    color: $darkgrey;
  }
  &.external {
    text-decoration: none;
    cursor: pointer;
  }
}

/*
 :::  LIST
-------------------------------------------------- */
li {
  list-style: none;
}

/*
 :::  IMAGES
-------------------------------------------------- */
img {
  max-width: 100%;
  height: auto;
}

/*
 :::  MISC
-------------------------------------------------- */
.dashed-separator {
  border-bottom: 1px dashed $black;
  margin: 0 20px;
}

/*
 :::  LOZAD
-------------------------------------------------- */
.lozad {
  opacity: 0;
}

.lozad[data-loaded="true"] {
  opacity: 1;
  @include transition(opacity, 250ms);
}

/*
 :::  STATE
-------------------------------------------------- */
.overflow-hidden {
  overflow: hidden;
}
