.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer-height;

  @include mq($until: xl) {
    height: 75px;
  }
 }

.footer-navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 10px 0;
  padding: 0 $gutter-width;

  @include mq($until: xl) {
    flex-direction: column;
    margin: 20px 0 20px 0;
  }
}

.footer-list {
  display: flex;
  // Remove default ul padding
  padding-left: 0;
  & li {
    list-style: none;
    // No margin-left on first external link
    &:first-child .footer-link.external {
      margin: 0 $spacer*1 0 0;

      @include mq($until: md) { 
        margin: 0 $spacer*1;
      }
    }
  }

  @include mq($until: md) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.footer-link {
  margin: 0 $spacer*1;
  font-size: $font-size-small;
  text-decoration: none;
}

// Margin for the footer newsletter-badge
.footer-link.newsletter-badge {
  @include mq($until: md) {
    margin: $spacer*1 0 $spacer*2 0;
  }
}

.footer-aside {
  display: flex;

  @include mq($until: md) {
    flex-direction: column;
    margin: $spacer*1 0;
  }
}

.footer-separator::after {
  content: '|';
  margin: 0 $spacer*1;
  position: relative;
  top: 1px;

  @include mq($until: md) {
    display: none;
  }
}

.newsletter-button {
  cursor: pointer;
  background: none;
  border: none;
  line-height: 0;
  -webkit-appearance: none;
}